import React, {useRef, useState} from "react";
import TopPages from "../../components/topPages";
import {FormattedMessage} from "react-intl";
import { Form, FormItem } from '../../components/form/form.tsx';
import {GoogleMap, useJsApiLoader} from "@react-google-maps/api";
const containerStyle = {
    height: '320px'
};

const ContactUsPage = ()=>{
    const refForm = useRef()
    const [center,setCenter]=useState({
        lat:32.8843232, lng: 13.1808255
    })
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCo8GI1_3Gbk8gXZF_4lwDJYTViap9BgZs"
    })
    const onSubmit= (e)=>{

    }
    if(!isLoaded){
        return (
            <></>
        )
    }
    return(
        <>
            <TopPages image={"2"} title={"contactUs"} subtitle={"contactUs"}></TopPages>
            <section className="section-padding">
                <div className="container">
                    <div className="section-title">
                        <h2><FormattedMessage id={"page.contactUs.main.title.1"}></FormattedMessage> <span><FormattedMessage id={"page.contactUs.main.title.2"}></FormattedMessage></span></h2>
                        <span className="s-title-icon"><i className="icofont icofont-diamond"></i></span>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="contact-info text-center">
                                <span><i className="fa fa-map-marker"></i></span>
                                <h4>
                                    <FormattedMessage
                                    id={"page.contactUs.our.address"}
                                    ></FormattedMessage>
                                </h4>
                                <h6>
                                    <FormattedMessage
                                    id={"component.footer.address"}
                                    ></FormattedMessage>
                                </h6>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="contact-info text-center">
                                <span><i className="fa fa-phone" aria-hidden="true"></i></span>
                                <h4>
                                    <FormattedMessage id={"page.contactUs.call.us"}
                                    ></FormattedMessage>
                                </h4>
                                <h6>+1 916-875-2586-458
                                </h6>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="contact-info text-center">
                                <span><i className="fa fa-map-marker"></i></span>
                                <h4>
                                    <FormattedMessage id={"page.contactUs.email.us"}
                                    ></FormattedMessage>
                                </h4>
                                <h6>contact@addalilgroup.com</h6>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="contact-info text-center">
                                <span><i className="fa fa-globe" aria-hidden="true"></i></span>
                                <h4>
                                    <FormattedMessage id={"page.contactUs.website"}
                                    ></FormattedMessage>
                                </h4>
                                <h6><a href={"www.addalilgroup.com"} target={"_blank"} >www.addalilgroup.com</a></h6>
                            </div>
                        </div>
                    </div>
                    <div className="row padding-60">
                        <div className="col-md-7 contact-team">
                            <h3 className="text-center"><FormattedMessage id={"page.contactUs.subtitle.1"}></FormattedMessage> <span><FormattedMessage id={"page.contactUs.subtitle.2"}></FormattedMessage></span></h3>
                            <div className="contact-send-message">
                                <div className="contact-form row">
                                    <Form
                                        ref={refForm}
                                        onSubmit={onSubmit}>
                                    <div className="col col-sm-6">
                                        <FormItem
                                            name="name"
                                            placeholder={{
                                                id: "page.contact.form.input.name.placeholder",
                                                defaultMessage: "Enter Name*",
                                            }}
                                            rules={[
                                                {
                                                    whitespace: true, messageOption: {
                                                        id: 'page.contact.form.input.name.valid',
                                                        defaultMessage: "Please enter your name ",
                                                    }
                                                },
                                                {
                                                    required: true, messageOption: {
                                                        id: 'page.contact.form.input.name.valid',
                                                        defaultMessage: "Please enter your name ",
                                                    }
                                                }

                                            ]}>
                                            <input type="text" className="form-control" placeholder="Enter Name*" />
                                        </FormItem>

                                    </div>
                                    <div className="col col-sm-6">
                                        <FormItem
                                            name="email"

                                            placeholder={{
                                                id: "page.login.input.message.email.placeholder",
                                                defaultMessage: "Enter E-mail*"
                                            }}
                                            rules={[
                                                {
                                                    required: true, messageOption: {
                                                        id: 'page.contact.form.input.email.required',
                                                        defaultMessage: "Please enter a valid email address"
                                                    }
                                                },
                                                {
                                                    whitespace: true, messageOption: {
                                                        id: 'page.contact.form.input.email.required',
                                                        defaultMessage: "Please enter a valid email address"
                                                    }
                                                },
                                                {
                                                    type: "email", messageOption: {
                                                        id: 'page.contact.form.input.email.required',
                                                        defaultMessage: "Please enter a valid email address",
                                                    }
                                                },

                                            ]}>
                                            <input type="email" className="form-control" placeholder="Enter E-mail*" />
                                        </FormItem>

                                    </div>
                                    <div className="col col-sm-6">
                                        <FormItem
                                            name="subject"

                                            placeholder={{
                                                id: "page.login.input.message.subject.placeholder",
                                                defaultMessage: "Objet"
                                            }}
                                            rules={[
                                                {
                                                    required: true, messageOption: {
                                                        id: 'page.contact.form.input.subject.required',
                                                        defaultMessage: "Please enter the subject of your request"
                                                    }
                                                },
                                                {
                                                    whitespace: true, messageOption: {
                                                        id: 'page.contact.form.input.subject.required',
                                                        defaultMessage: "Please enter the subject of your request"
                                                    }
                                                }

                                            ]}>
                                            <input type="text" className="form-control" placeholder="Enter Subject*" />
                                        </FormItem>

                                    </div>
                                    <div className="col col-sm-6">
                                        <FormItem
                                            name="phone"
                                            placeholder={{
                                                id: "page.contact.form.input.phone.placeholder",
                                                defaultMessage: "+216 71 000 000",
                                            }}
                                            rules={[
                                                {
                                                    whitespace: true, messageOption: {
                                                        id: 'page.contact.form.input.phone.type.valid',
                                                        defaultMessage: "Please enter a valid phone number",
                                                    }
                                                },
                                                {
                                                    required: true, messageOption: {
                                                        id: 'page.contact.form.input.phone.type.valid',
                                                        defaultMessage: "Please enter a valid phone number ",
                                                    }
                                                },
                                                {
                                                    type: "customphone", messageOption: {
                                                        id: 'page.contact.form.input.phone.type.valid',
                                                        defaultMessage: "Please enter a valid phone number ",
                                                    }
                                                },

                                            ]}>
                                            <input type="text" className="form-control" placeholder="Enter Phone*" />
                                        </FormItem>

                                    </div>
                                    <div className="col col-sm-12">
                                        <FormItem
                                            name="content"
                                            placeholder={{
                                                id: "page.contact.form.input.content.placeholder",
                                                defaultMessage: "Enter Your Message*",
                                            }}
                                            rules={[
                                                {
                                                    whitespace: true, messageOption: {
                                                        id: 'page.contactUs.form.input.content.message.valid',
                                                        defaultMessage: "Please enter your message ",
                                                    }
                                                },
                                                {
                                                    required: true, messageOption: {
                                                        id: 'page.contactUs.form.input.content.message.valid',
                                                        defaultMessage: "Please enter your message",
                                                    }
                                                },

                                            ]}>
                                            <textarea   placeholder="Enter Your Message*" className="form-control" name="message"></textarea>
                                        </FormItem>
                                    </div>
                                    <div className="col col-sm-12 text-center">
                                        <button type="submit"
                                                className="btn btn-default btn-style hvr-shutter-out-vertical">
                                            <FormattedMessage
                                            id={"page.contactUs.button.send"}
                                            ></FormattedMessage>
                                        </button>
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div id="map" className="thumbnail">
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={14}>
                                   </GoogleMap>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ContactUsPage
