import React from "react";
import TopPages from "../../../components/topPages";
import {FormattedMessage} from "react-intl";

const ProjectManagementPage = ()=>{
    return (
        <>
            <TopPages image={"5"} title={"services"} subtitle={"projectmanagement"}></TopPages>

            <section id="about" className="about section-padding-top">
                <div className="container">
                    <div className="section-title">
                        <span className="s-title-icon"><i className="icofont icofont-diamond"></i></span>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <a  className="img-about">
                                <img src="assets/images/slider/8.jpg" alt="" className="img-responsive" />
                            </a>
                        </div>
                        <div className="col-md-7">
                            <div className="about-details">

                                <p><FormattedMessage id="page.service.projectmanagement.description"></FormattedMessage> </p>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ProjectManagementPage
