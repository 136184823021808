
import React, {
    useState, useEffect,
    useImperativeHandle, useRef, createRef,
} from 'react'
//import { IonItem, IonIcon, IonLabel, useIonViewWillEnter, IonGrid, IonRow, IonCol, IonPopover } from '@ionic/react';
import schema from 'async-validator';
//import { closeCircle, checkmarkCircle, eyeOutline, eyeOffOutline, closeCircleOutline, informationCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
//import './form.scss'
import { useIntl, FormattedMessage } from 'react-intl';
import { isArray, isNumber } from 'util';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();


// todo  update icons value
const checkmarkCircle = ""
const closeCircle = ""
const eyeOutline = ""
const eyeOffOutline = ""


interface IForm {
    onSubmit?: (v: any) => void,
    handleChange?: () => void,
    maxRefs?:boolean,
    children: any
}
interface IFormItem {
    internalchangeevent?: () => void,
    onChangeCB?: (v:any) => void,
    children?: any,
    ref?: any,//((instance: unknown) => void) | MutableRefObject<unknown> | null,
    prefix?: string,
    hasFeedback?: boolean,
    rules?: any,
    name: string,
    custom?: boolean,
    placeholder?: any,
    inputAdd?: boolean,
    label?: any,
    regex?: RegExp,
    initialValue?: any,
    required?: boolean,
    toggleInputPassword?: boolean,
    passwordHint?: boolean,
    maxRefs?:boolean,
    inputDate?: boolean
}
const Form = React.memo(React.forwardRef((props: IForm, ref?: React.Ref<any>) => {
    
    const localRef: any[] = []
    const custoCreateRef = ((children: any) => {
        if (!children)
            return;
        if (Array.isArray(children)) {
            for (let i = 0; i < children.length; i++) {
                if (children[i].type === FormItem) {
                    localRef.push(createRef())
                } else {
                    if (children[i].props.children) {
                        custoCreateRef(children[i].props.children)
                    }
                }
            }
        }
        if (children.type === FormItem) {
            localRef.push(createRef())
        } else {
            if (children.props && children.props.children) {
                custoCreateRef(children.props.children)
            }
        }
        return;

    })

    custoCreateRef(props.children)
    let refIndex = 0;
    let ___localRef: any[] = []
    if(props.maxRefs){
        for(let ___i=0;___i<1000;___i++){
            ___localRef.push(createRef())
        }
    }
    const elementsRef = useRef(props.maxRefs ? [...___localRef]: [...localRef]);
    const submitHandler = async (e: any) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        let canHandleProps = true;
        let formValues = {}
        for (let i = 0; i < (props.maxRefs?localRef.length : elementsRef.current.length); i++) {
            if (elementsRef.current[i].current && elementsRef.current[i].current.forceValidateInput) {
                const result = await elementsRef.current[i].current.forceValidateInput()
                if (result.isValid) {
                    delete result.isValid;
                    formValues = { ...formValues, ...result }
                } else {
                    canHandleProps = false
                }
            }
        }
        
        if (canHandleProps && props.onSubmit) {
            try {
                props.onSubmit(formValues)
                return
            } catch (error) {
            }
        } else if (canHandleProps && !!!props.onSubmit) {
            return formValues
        } else if (canHandleProps === false) {
            return false
        }
    }
    const mapChildren = (children: JSX.Element[]): any => {
        return React.Children.map(children,
            (child, index) => {
                return transformChild(child)
            })
    }
    const internalchangeevent = () => {
        if (props.handleChange) {
            props.handleChange()
        }
    }
    const transformChild = (_child: JSX.Element): any => {
        if (_child.type === FormItem) {
            
            let elementChild: React.ReactElement<IFormItem> = _child
            return React.createElement(FormItemWrapper, {
                internalchangeevent: internalchangeevent,
                ref: elementsRef.current[refIndex++],
                ...elementChild.props,
            }, _child)
        }
        if (!_child.props) {
            return _child;
        }
        if (Array.isArray(_child.props.children)) {
            return React.cloneElement(_child, { ..._child.props }, mapChildren(_child.props.children))
        }
        if (_child.props.children) {
            return React.cloneElement(_child, { ..._child.props }, transformChild(_child.props.children))
        }
        return _child;
    }

    const initChildForm = () => {
        if (!props.children) {
            return <></>
        }
        if (Array.isArray(props.children)) {
            return mapChildren(props.children)
        }
        return transformChild(props.children)
    }
    const resetForm = async () => {
       
       
        for (let i = 0; i < (props.maxRefs?localRef.length : elementsRef.current.length); i++) {
            if (elementsRef.current[i].current && elementsRef.current[i].current.forceResetInput) {
                await elementsRef.current[i].current.forceResetInput()
                
            }
        }
        
        
    }
    useImperativeHandle(ref, () => ({
        async submitForm() {
            return await submitHandler(false)
        },
        async resetForm() {
            return await resetForm()
        }
    }));
    return (
        <form
            ref={ref}
            noValidate
            onSubmit={submitHandler}>
            {
                initChildForm()
            }

        </form>
    )
}))
const FormItemWrapper = React.memo(React.forwardRef((props: IFormItem, ref) => {
    const internalchangeevent = () => {
        if (props.internalchangeevent)
            props.internalchangeevent()
    }
    const el = React.cloneElement<IFormItem>(props.children, {
        internalchangeevent: internalchangeevent,
        ref: ref
    })
    return <>
        {el}
    </>
}))

type errorValidType = boolean | undefined;
const FormItem = React.memo(React.forwardRef((props: IFormItem, ref?: React.Ref<any>) => {

    const [error, setError] = useState<errorValidType>(false);
    const [icon, setIcon] = useState<any>(null);
    const [inputTypePasswordActive, setInputTypePasswordActive] = useState<boolean>(false);
    const [classs, setClasss] = useState<string>("");
    const [valid, setValid] = useState<errorValidType>(false);
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [value, setValue] = useState<any>(props.initialValue && isArray(props.initialValue) ? props.initialValue.join(",") : isNumber(props.initialValue) ? `${props.initialValue}` : props.initialValue)
    const intl = useIntl()
    const [valueHasChanged, setValueHasChanged] = useState<boolean>(false)
    /*useIonViewWillEnter(() => {
        setValue(props.initialValue)
        reset()
    });*/
    const internalOnchangeEventHanlder = (e: any) => {
        e.preventDefault();
        setValue(e.target.value || e.value)
        if (props.internalchangeevent)
            props.internalchangeevent()
        /*if (props.children.props && props.children.props.onIonChange) {
            props.children.props.onIonChange(e)
        }*/
        if (props.onChangeCB){
            props.onChangeCB(e.target.value)
        }
        setValueHasChanged(true)
    }

    const internalOnchangeEventHanlderAddresse = (e: any) => {
        setValue(e)
        setValueHasChanged(true)
        if (props.internalchangeevent)
            props.internalchangeevent()
    }

    const getCallBack = (e: any) => {

        if (props.inputAdd === true || props.custom === true) {
            return internalOnchangeEventHanlderAddresse(e)
        } else {
            return internalOnchangeEventHanlder(e)
        }
    }
    //={(e) => setText(e.htmlValue)}
    

    const itemOption: any = {
        value: value,
        // onIonKeyPress: keypress,
        //onIonChange: getCallBack,
        onChange: getCallBack,
       // onTextChange: getCallBackHtml
    }
    if (props.placeholder) {
        itemOption["placeholder"] = intl.formatMessage({ ...props.placeholder })
    }
    if (props.toggleInputPassword) {
        itemOption["type"] = inputTypePasswordActive ? "text" : "password"
    }
    const Item = React.cloneElement(props.children, {
        ...itemOption
    })
    useImperativeHandle(ref, () => ({
        async forceValidateInput() {
            const isValid = await validateInput()
            if (isValid) {
                const inputValue: any = {}
                inputValue[props.name] = value
                return {
                    isValid: true,
                    ...inputValue
                }
            }
            return {
                isValid: false
            }
        },async forceResetInput(){
            setValueHasChanged(false)
            setValue("")
            reset()
        }
    }));
    const reset = () => {
        setError(false)
        setValid(false)
        setIcon(null)
        setErrorMessage("")
        setClasss("")
    }
    const checkRequired = () => {
        return !!props.required
    }
    const validateInput = async () => {
        if (!props.rules) {
            return true;
        }
        reset()
        const inputValue: any = {}
        inputValue[props.name] = value
        try {
            for (let i = 0; i < props.rules.length; i++) {
                //messageOption
                const _rules = { ...props.rules[i] }
                if(props.inputDate === true  && !!!value ){
                            const messgae = intl.formatMessage({
                                id: 'page.article.form.input.date.valide',
                                defaultMessage: "Veuillez saisir  une date valide",
                            })
                        let err = new Error(messgae);
                        err.name =  "custom.error.regex"
                        throw err
                }else if (_rules.type && _rules.type === "customphone") {
                    if (value)
                        await validatePhoneNumber(inputValue[props.name], intl.formatMessage({
                            ..._rules.messageOption
                        }))
                } else if (_rules.type && _rules.type === "regex") {
                    // new RegExp()
                    if (/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[é'§èçà!@#$%^&*"()\][])[\wé'§èçà!@#$%^&*"\][()]{8,}$/.test(value) === false) {
                        const messgae = intl.formatMessage({
                            ..._rules.messageOption
                        })
                        let err = new Error(messgae);
                        err.name = "custom.error.regex"
                        throw err
                    }
                } else {
                    if (_rules.messageOption) {
                        _rules["message"] = intl.formatMessage({
                            ..._rules.messageOption
                        })
                    }
                    if (_rules.type && _rules.type === "email") {
                        inputValue[props.name] = value.trimEnd()
                    }
                    const _schema: any = {};
                    _schema[props.name] = _rules
                    const validator = new schema(_schema);
                    await validator.validate(inputValue)
                }
            }
            setValid(true)
            setIcon(checkmarkCircle)
            return true
        } catch (error) {
            setError(true)
            if (error && error.name === "custom.error.regex")
                setErrorMessage(error.message)
            if (error && error.errors && error.errors[0])
                setErrorMessage(error.errors[0].message)
            setIcon(closeCircle)
            return false
        }
    }
    useEffect(() => {
        if (!valueHasChanged || value === undefined) return
        validateInput()
        // eslint-disable-next-line
    }, [value, valueHasChanged]);
    useEffect(() => {
        if (error) setClasss("error")
        if (valid) setClasss("valid")
    }, [error, valid]);
    
  
    if (props.custom || props.inputAdd)
        return (
            <div
                className="form-item-wrapper custom-wrapper"
                ref={ref}>
                {props.label && <div className="custum-label">
                    {props.label} {checkRequired() && <span>*</span>}
                </div>}
                {Item}
                {
                    error && <div className="error-explain">
                        {errorMessage}
                    </div>
                }
            </div>
        )
    //const __className
    return (
        <div className={`${error && "p-input-error-custom-wrapper"}`}>
        <div className={`p-field p-fluid  ${props.prefix  && "p-inputgroup"}`}
            //forvalidate={forvalidate} <label htmlFor="name1">Name</label>
            ref={ref}>
            {!!!props.prefix && props.label && <label> { intl.formatMessage({ ...props.label })} {checkRequired() && <span>*</span>}</label>}
                     {props.prefix && <span className="p-inputgroup-addon">
                                <i className={`pi ${props.prefix}`}></i>
                            </span>}
                {Item}
                
            
           
        </div>
         {
                error &&   <small className="p-error p-d-block">
                 {errorMessage}
                 </small>
                    
            }
            </div>
    )
}))

export {
    Form,
    FormItem
};
function validatePhoneNumber(_v: string, message: string) {
    return new Promise((resolve, reject) => {
        const error = {
            errors: [{ message }]
        }
        try {
            const number = phoneUtil.parseAndKeepRawInput(_v, 'TN');
            if (phoneUtil.isValidNumber(number)) {
                return resolve(true)
            }
        } catch (error) {

        }

        reject(error)
    })
} 