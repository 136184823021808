import React ,{useState} from "react";
import {Link,NavLink} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {toggleLanguageAction} from "./action";
import {connect} from "react-redux";

const Header = (props)=>{
    const [openMenu , setOpenMenu]=useState(false)
    const [openSubServiceMenu , setOpenSubServiceMenu]=useState(false)

    const resetAll=()=>{
        setOpenSubServiceMenu(false)
        setOpenMenu(false)
    }
    const changeLanguage=async ()=>{
        try {
            await props.toggleLanguage()

        }catch (e){

        }
        resetAll()
    }
    return (
        <header>
            <div className="hidden-xs hidden-sm nav-top primary-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="nav-top-access">

                                <ul>
                                    <li> <i className="fa fa-phone"></i> +1-1459-236-756 </li> <li> <i className="fa fa-envelope" aria-hidden="true"></i> contact@addalilgroup.com</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 text-right">
                            <div className="nav-top-social">
                                <ul>
                                    <li onClick={changeLanguage}><a> <FormattedMessage id={"component.top.header.language"}></FormattedMessage> </a></li>
                                    <li><a ><i className="fa fa-facebook"></i></a></li>
                                    <li><a ><i className="fa fa-twitter"></i></a></li>
                                    <li><a ><i className="fa fa-linkedin"></i></a></li>
                                    <li><a ><i className="fa fa-pinterest-p"></i></a></li>
                                    <li><a ><i className="fa fa-instagram"></i></a></li>
                                    <li><a ><i className="fa fa-google-plus"></i></a></li>
                                    <li><a ><i className="fa fa-youtube-play"></i></a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="menu-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 dirltr">
                            <div className="logo">
                                 <a>
                                    <h3><span> <FormattedMessage id={"component.header.title.part.1"}></FormattedMessage></span>   <FormattedMessage id={"component.header.title.part.2"}></FormattedMessage></h3>
                                 </a>
                            </div>

                            <div className="responsive-menu">

                                <div className="slicknav_menu">
                                    <a
                                        onClick={()=> setOpenMenu((prev)=>!prev)}
                                        aria-haspopup="true" role="button" tabIndex="0" className="slicknav_btn slicknav_open">
                                        <span  className="slicknav_menutxt">MENU</span>
                                    <span className="slicknav_icon">
                                        <span
                                        className="slicknav_icon-bar"></span><span
                                        className="slicknav_icon-bar"></span><span
                                        className="slicknav_icon-bar"></span></span>
                                    </a>
                                    { openMenu && <ul className="slicknav_nav" >
                                        <li>
                                            <NavLink  onClick={resetAll} activeClassName="current-page-item" to="/home">
                                                <FormattedMessage
                                                id={"components.header.button.home"}
                                                ></FormattedMessage>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink  onClick={resetAll} activeClassName="current-page-item" to="/aboutUs">
                                                <FormattedMessage
                                                    id={"components.header.button.aboutUs"}
                                                ></FormattedMessage>
                                            </NavLink>
                                        </li>
                                        <li   onClick={()=> setOpenSubServiceMenu((prev)=>!prev)} className="slicknav_collapsed slicknav_parent">
                                            <a className="slicknav_item slicknav_row">
                                                <span><FormattedMessage
                                                    id={"components.header.button.services"}
                                                ></FormattedMessage></span>
                                                <span className="slicknav_arrow">►</span></a>
                                            {openSubServiceMenu && <ul role="menu" className="slicknav_hidden"  >
                                                <ul>
                                                    <li  onClick={resetAll}><NavLink to={"/engineering"} > <FormattedMessage
                                                        id={"components.header.button.services.engineering"}
                                                    ></FormattedMessage></NavLink></li>
                                                    <li  onClick={resetAll}><NavLink to={"/project-management"}><FormattedMessage id={"components.header.button.services.project.management"}></FormattedMessage></NavLink></li>
                                                    <li  onClick={resetAll}><NavLink to={"/construction"} ><FormattedMessage
                                                        id={"components.header.button.services.construction"}
                                                    ></FormattedMessage></NavLink></li>
                                                    <li  onClick={resetAll}><NavLink to={"/procurement"} ><FormattedMessage
                                                        id={"components.header.button.services.procurement"}
                                                    ></FormattedMessage></NavLink></li>
                                                    <li  onClick={resetAll}><NavLink to={"/commissioning"} ><FormattedMessage
                                                        id={"components.header.button.services.commission"}
                                                    ></FormattedMessage></NavLink></li>
                                                    <li  onClick={resetAll}><NavLink to={"/operating"} ><FormattedMessage
                                                        id={"components.header.button.services.operating"}
                                                    ></FormattedMessage></NavLink></li>
                                                </ul>
                                                    </ul>}
                                        </li>



                                        <li  onClick={resetAll}><a ><FormattedMessage
                                            id={"components.header.button.contact"}
                                        ></FormattedMessage></a></li>
                                        <li onClick={changeLanguage} > <NavLink to={"/contactUs"}>
                                            <FormattedMessage id={"component.top.header.language"}></FormattedMessage>
                                        </NavLink> </li>
                                    </ul>}
                                </div>
                            </div>

                        </div>
                        <div className="col-md-9 col-sm-12">
                            <div className="mainmenu">
                                <nav>
                                    <ul id="navigation">
                                        <li ><NavLink activeClassName="current-page-item" to="/home">

                                            <FormattedMessage
                                                id={"components.header.button.home"}
                                            ></FormattedMessage>
                                        </NavLink>

                                        </li>
                                        <li>
                                            <NavLink activeClassName="current-page-item" to="/aboutUs">
                                                <FormattedMessage
                                                    id={"components.header.button.aboutUs"}
                                                ></FormattedMessage>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <a ><FormattedMessage
                                                id={"components.header.button.services"}
                                            ></FormattedMessage></a>
                                            <ul>
                                                    <li><Link to={"/engineering"} >
                                                        <FormattedMessage
                                                            id={"components.header.button.services.engineering"}
                                                        ></FormattedMessage>
                                                        </Link></li>
                                                    <li><Link to={"/project-management"}> <FormattedMessage id={"components.header.button.services.project.management"}></FormattedMessage> </Link></li>
                                                    <li><Link to={"/construction"} >  <FormattedMessage
                                                        id={"components.header.button.services.construction"}
                                                    ></FormattedMessage></Link></li>
                                                    <li><Link to={"/procurement"} ><FormattedMessage
                                                        id={"components.header.button.services.procurement"}
                                                    ></FormattedMessage></Link></li>
                                                    <li><Link to={"/commissioning"} ><FormattedMessage
                                                        id={"components.header.button.services.commission"}
                                                    ></FormattedMessage></Link></li>
                                                    <li><Link to={"/operating"} ><FormattedMessage
                                                        id={"components.header.button.services.operating"}
                                                    ></FormattedMessage></Link></li>
                                                </ul>
                                            </li>


                                        <li ><NavLink to={"/contactUs"} ><FormattedMessage
                                            id={"components.header.button.contact"}
                                        ></FormattedMessage></NavLink></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

const mapDispatchToProps = (dispatch) => ({
    toggleLanguage: () => dispatch(toggleLanguageAction())
})
export default connect(null, mapDispatchToProps)(React.memo(Header));
