import React from "react";
import {FormattedMessage} from "react-intl";
import TopPages from "../../../components/topPages";

const EngineeringPage =()=>{
    return (
        <>
            <TopPages image={"5"} title={"services"} subtitle={"engineering"}></TopPages>

            <section id="about" className="about section-padding-top">
                <div className="container">
                    <div className="section-title">
                        <span className="s-title-icon"><i className="icofont icofont-diamond"></i></span>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <a  className="img-about">
                                <img src="assets/images/slider/11.jpg" alt="" className="img-responsive" />
                            </a>
                        </div>
                        <div className="col-md-7">
                            <div className="about-details">

                                <p><FormattedMessage id="page.service.engineering.description"></FormattedMessage> </p>
                                <ul className="image-contact-list">
                                    <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.engineering.list.1"} ></FormattedMessage> </span>
                                    </li>
                                    <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.engineering.list.2"} ></FormattedMessage> </span>
                                    </li>
                                    <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.engineering.list.3"} ></FormattedMessage> </span></li>
                                    <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.engineering.list.4"} ></FormattedMessage> </span></li>
                                    <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.engineering.list.5"} ></FormattedMessage> </span></li>
                                    <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.engineering.list.6"} ></FormattedMessage> </span></li>

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default EngineeringPage
