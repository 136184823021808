import React from "react";
import TopPages from "../../../components/topPages";
import {FormattedMessage} from "react-intl";

const CommissioningPage=()=>{
    return (
        <>
        <TopPages image={"2"} title={"services"} subtitle={"commissioning"}></TopPages>

    <section id="about" className="about section-padding-top">
        <div className="container">
            <div className="section-title">
                <span className="s-title-icon"><i className="icofont icofont-diamond"></i></span>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <a  className="img-about">
                        <img src="assets/images/slider/1.jpg" alt="" className="img-responsive" />
                    </a>
                </div>
                <div className="col-md-7">
                    <div className="about-details">

                        <p><FormattedMessage id="page.service.commissioning.description"></FormattedMessage> </p>
                        <ul className="image-contact-list">
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.commissioning.list.1"} ></FormattedMessage> </span>
                            </li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.commissioning.list.2"} ></FormattedMessage> </span>
                            </li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.commissioning.list.3"} ></FormattedMessage> </span></li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.commissioning.list.4"} ></FormattedMessage> </span></li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.commissioning.list.5"} ></FormattedMessage> </span></li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.commissioning.list.6"} ></FormattedMessage> </span></li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.commissioning.list.7"} ></FormattedMessage> </span></li>

                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </section>
</>
    )
}
export default CommissioningPage
