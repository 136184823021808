import React from "react";
import Particles from "react-tsparticles";
import {FormattedMessage} from "react-intl";


const Slider = () => {
    return(
    <div style={{ position: "relative"}}>
        <Particles
            id="Particle-ground"
            options={{
            fpsLimit: 60,
            interactivity: {
                detectsOn: "canvas",
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                    resize: true,
                },
                modes: {
                    bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 40,
                    },
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#ffffff",
                },
                links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                },
                collisions: {
                    enable: true,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 6,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 800,
                    },
                    value: 80,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    random: true,
                    value: 5,
                },
            },
            detectRetina: true,
        }}
        />
    <section id="home" >
        <div className="main-slider-1 white-clr-all">
            <div id="carosel-mr-1" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner main-sld" role="listbox">
                    <div className="item active main-sld">
                        <div className="slider-bg"
                        style={{backgroundImage:"url('assets/images/slider/10.jpg')"}}></div>
                        <div className="slider-cell">
                            <div className="slider-ver">
                                <div className="slider-con text-center">
                                    <h1> <FormattedMessage id={"component.slider.title.part.1"}></FormattedMessage> <span> <FormattedMessage id={"component.slider.title.part.2"}></FormattedMessage> </span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
    )
}
export  default Slider
