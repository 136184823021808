import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import language from './language'

export default combineReducers({
    language: persistReducer({
        key: 'addalil-group',
        storage,
    }, language)
})
