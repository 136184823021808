import { Route ,Switch,Redirect } from 'react-router-dom';

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "./css/bootstrap.min.css"
import "./css/animate.min.css"
import "./css/owl.carousel.css"
import "./css/slick.css"
import "./css/slicknav.min.css"
import "./css/slick-theme.css"
import "./css/font-awesome.min.css"
import "./css/icofont.css"
import "./css/jquery.lineProgressbar.min.css"
import "./css/owl.theme.default.css"
import "./css/magnific-popup.css"
import "./css/style.css"
import "./css/responsive.css"
import "./css/colors/green-color.css"
import Header from "./components/header";
import Footer from "./components/footer";
import HomePage from "./pages/home";
import AboutUsPage from "./pages/aboutus";
import EngineeringPage from "./pages/services/engineering";
import ProjectManagementPage from "./pages/services/projectmanagement";
import ConstructionPage from "./pages/services/construction";
import ProcurementPage from "./pages/services/procurement";
import CommissioningPage from "./pages/services/commissioning";
import OperatingPage from "./pages/services/operating";
import ContactUsPage from "./pages/contactUs";
function App() {
  return (
      <>
          <Header></Header>
            <Switch>
                {/* <Route path="/" exact component={Dashboard} />
                 */}
                <Route path="/home" exact component={HomePage} />
                <Route path="/aboutUs" exact component={AboutUsPage} />
                <Route path="/engineering" exact component={EngineeringPage} />
                <Route path="/project-management" exact component={ProjectManagementPage} />
                <Route path="/construction" exact component={ConstructionPage} />
                <Route path="/procurement" exact component={ProcurementPage} />
                <Route path="/commissioning" exact component={CommissioningPage} />
                <Route path="/operating" exact component={OperatingPage} />
                <Route path="/contactUs" exact component={ContactUsPage} />
                <Redirect to="/home" />
            </Switch>
            <Footer></Footer>
      </>
  );
}

export default App;
