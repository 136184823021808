import React from 'react';
import ReactDOM from 'react-dom';
import {   Router } from 'react-router-dom';
import { createBrowserHistory } from 'history'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Translate from "./components/translate";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './reducers/store'
const history = createBrowserHistory()

    ReactDOM.render(
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router history={history}>
                        <Translate>
                            <App />
                        </Translate>
                    </Router>
                </PersistGate>
            </Provider>
        </>,
        document.getElementById('root')
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
