import React from "react";
import TopPages from "../../components/topPages";
import {FormattedMessage} from "react-intl";

const AboutUsPage = ()=>{
    return (
        <>
            <TopPages image={"4"} title={"aboutus"} subtitle={"aboutus"}></TopPages>
            <section id="about" className="about section-padding-top">
                <div className="container">
                    <div className="section-title">
                        <h2> <FormattedMessage id={"page.aboutus.title.part.1"}></FormattedMessage> <span>  <FormattedMessage id={"page.aboutus.title.part.2"}></FormattedMessage> </span></h2>
                        <span className="s-title-icon"><i className="icofont icofont-diamond"></i></span>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <a  className="img-about">
                                <img src="assets/images/slider/2.jpg" alt="" className="img-responsive" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <div className="about-details">
                                <h5> <FormattedMessage id={"page.aboutus.subtitle"}></FormattedMessage> </h5>
                                <p> <FormattedMessage id={"page.aboutus.description"}></FormattedMessage> </p>
                                <ul className="image-contact-list">
                                    <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.aboutus.list.1"}></FormattedMessage> </span>
                                    </li>
                                    <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.aboutus.list.2"}></FormattedMessage> </span>
                                    </li>
                                    <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.aboutus.list.3"}></FormattedMessage> </span></li>
                                    <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.aboutus.list.4"}></FormattedMessage> </span></li>

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default AboutUsPage
