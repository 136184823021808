

const initState = {
    selectedLanguage: "ar",

}
const languageToggle={
    "ar":"en",
    "en":"ar"
}


export default (state = initState, action) => {
    switch (action.type) {
        case "toggleLanguage":
            return{
                selectedLanguage: languageToggle[state.selectedLanguage]
            }
            break;
        default:
            return state;
    }
};
