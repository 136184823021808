import  React from 'react'
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

const TopPages=(props)=>{
    return (
        <section
            style={{ backgroundImage:`url("assets/images/slider/${props.image}.jpg")`}}
            className="page-title page-bg bg-opacity section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2><FormattedMessage id={`component.top-page.breadcrumb.title.${props.title}`} ></FormattedMessage></h2>
                        <div className="breadcrumb">
                            <ul>
                                <li><Link to="/home">
                                    <FormattedMessage id={"component.top-page.breadcrumb.home"} ></FormattedMessage>

                                </Link></li>
                                <li> <FormattedMessage id={`component.top-page.breadcrumb.subtitle.${props.subtitle}`} ></FormattedMessage> </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TopPages
