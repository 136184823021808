import React from 'react'
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import reducer from '.'
//import DevTools from './devtools';
import { persistStore } from 'redux-persist'


// disable right click
if (process.env.NODE_ENV !== 'development')
    document.addEventListener('contextmenu', event => event.preventDefault());

const store = process.env.NODE_ENV === 'development'
    ? createStore(reducer, compose(applyMiddleware(thunk)))
    : createStore(reducer, applyMiddleware(thunk));
const persistor = persistStore(store)
export {
    store,
    persistor,
    
}
