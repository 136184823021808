import React from "react"
import TopPages from "../../../components/topPages";
import {FormattedMessage} from "react-intl";
const ProcurementPage = ()=>{
    return (
        <>
        <TopPages image={"4"} title={"services"} subtitle={"procurement"}></TopPages>

    <section id="about" className="about section-padding-top">
        <div className="container">
            <div className="section-title">
                <span className="s-title-icon"><i className="icofont icofont-diamond"></i></span>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <a  className="img-about">
                        <img src="assets/images/slider/9.jpg" alt="" className="img-responsive" />
                    </a>
                </div>
                <div className="col-md-7">
                    <div className="about-details">

                        <p><FormattedMessage id="page.service.procurement.description"></FormattedMessage> </p>
                        <ul className="image-contact-list">
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.procurement.list.1"} ></FormattedMessage> </span>
                            </li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.procurement.list.2"} ></FormattedMessage> </span>
                            </li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.procurement.list.4"} ></FormattedMessage> </span></li>
                            <li> <span> <FormattedMessage id={"page.service.procurement.list.separator"} ></FormattedMessage> </span></li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.procurement.list.5"} ></FormattedMessage> </span></li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.procurement.list.6"} ></FormattedMessage> </span></li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.procurement.list.7"} ></FormattedMessage> </span></li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.procurement.list.8"} ></FormattedMessage> </span></li>
                            <li><i className="icofont icofont-check-alt"></i> <span> <FormattedMessage id={"page.service.procurement.list.9"} ></FormattedMessage> </span></li>

                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </section>
</>
    )
}

export  default ProcurementPage
