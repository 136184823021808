import React from "react";
import Slider from "../../components/slider";
import AboutUs from "../../components/aboutus";

const HomePage = ()=>{
    return (
       <>
           <Slider></Slider>
           <AboutUs></AboutUs>
       </>
    )
}
export default  HomePage
