import React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

const AboutUs = ()=>{
    return <section id="about" className="about section-padding-top">
        <div className="container">
            <div className="section-title">
                <h2> <FormattedMessage id={"component.aboutUs.title.part.1"}></FormattedMessage> <span> <FormattedMessage id={"component.aboutUs.title.part.2"} ></FormattedMessage> </span></h2>
                <span className="s-title-icon"><i className="icofont icofont-diamond"></i></span>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <a  className="img-about">
                        <img src="assets/images/slider/3.jpg" alt="" className="img-responsive" />
                    </a>
                </div>
                <div className="col-md-6">
                    <div className="about-details">
                        <h5> <FormattedMessage id={"page.aboutus.subtitle"}></FormattedMessage> </h5>
                        <p> <FormattedMessage id={"component.aboutUs.description"} ></FormattedMessage> </p>
                        <Link  to="/aboutUs" className="btn btn-default btn-style hvr-shutter-out-vertical" > <FormattedMessage id={"component.aboutUs.button.read.more"} ></FormattedMessage> </Link>
                    </div>

                </div>
            </div>
        </div>
    </section>
}
export default AboutUs
