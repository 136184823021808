import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Footer = () => {
    return <footer className="footer-bg section-padding-top footer m-t-80">
        <div className="footer-widget-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="f-widget">
                            <Link to={"/home"}>
                                <h4 className="logo-text1"><span><FormattedMessage id={"component.header.title.part.1"} ></FormattedMessage></span>  <FormattedMessage id={"component.header.title.part.2"} ></FormattedMessage></h4>
                            </Link>
                            <p className="m-t-30"> <FormattedMessage id={"component.footer.description"}></FormattedMessage> </p>
                            <ul className="f-address">
                                <li><i className="fa fa-map-marker" aria-hidden="true"></i> <FormattedMessage id={"component.footer.address"}></FormattedMessage>
                                </li>
                                <li><i className="fa fa-phone"></i> +1-1459-236-756</li>
                                <li><i className="fa fa-envelope" aria-hidden="true"></i> contact@addalilgroup.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="f-widget">
                            <div className="f-widget-title">
                                <h4><FormattedMessage id={"component.footer.useful.link.title"}></FormattedMessage></h4>
                            </div>
                            <ul className="useful-links">
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i> <a href={"https://d22ek3q6kok09n.cloudfront.net/addalil/Commercial%20register.pdf"} target="_blank" >
                                    <FormattedMessage id={"component.footer.useful.link.commercial.register"}></FormattedMessage>
                                </a></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i> <a href={"https://d22ek3q6kok09n.cloudfront.net/addalil/Establishment%20contract.pdf"} target="_blank" >
                                    <FormattedMessage id={"component.footer.useful.link.establishment.contract"}></FormattedMessage>
                                </a></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i> <a href={"https://d22ek3q6kok09n.cloudfront.net/addalil/Partnership%20license.pdf"} target="_blank" >
                                    <FormattedMessage id={"component.footer.useful.link.partnership.license"}></FormattedMessage></a></li>
                                <li><i className="fa fa-caret-right" aria-hidden="true"></i> <a href={"https://d22ek3q6kok09n.cloudfront.net/addalil/Tax%20payment%20receipt.pdf"} target="_blank" >
                                    <FormattedMessage id={"component.footer.useful.link.tax.payment.receipt"}></FormattedMessage>
                                </a></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row text-center">
                    <div className="copyright-social">
                        <ul className="social-profile">
                            <li><a ><i className="fa fa-facebook"></i></a></li>
                            <li><a ><i className="fa fa-twitter"></i></a></li>
                            <li><a ><i className="fa fa-linkedin"></i></a></li>
                            <li><a ><i className="fa fa-pinterest-p"></i></a></li>
                            <li><a ><i className="fa fa-instagram"></i></a></li>
                            <li><a ><i className="fa fa-google-plus"></i></a></li>
                            <li><a ><i className="fa fa-youtube-play"></i></a></li>
                        </ul>
                    </div>
                    <div className="copyright-text">
                        <p>© Copyright {(new Date()).getFullYear()}. by <a href="mailto:amir.benyaala@nodetech-consulting.com">nodetech consulting.</a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer
