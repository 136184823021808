import React, {useEffect, useState} from 'react'
import { IntlProvider } from 'react-intl'
import ar from "./ar.json";
import en from "./en.json";
import {connect} from "react-redux";
const language ={
    "ar":ar,
    "en":en
}
const direction ={
    "ar":"rtl",
    "en":"ltr"
}
const Translate = (props) => {

    const [messages,setMessages]=useState(language[props.selectedLanguage])
    const [locale,setLocal]=useState(`${props.selectedLanguage}`)
    useEffect(()=>{
        toggleLanguage()
    },[props.selectedLanguage])
    const  toggleLanguage =()=>{
        setLocal(props.selectedLanguage)
        setMessages(language[props.selectedLanguage])
        document.getElementById("dir").dir = direction[props.selectedLanguage]
    }
    return <IntlProvider
        locale={locale} messages={messages}>
        {props.children}
    </IntlProvider>
}
const mapStateToProps = (state) => ({
    selectedLanguage: state.language.selectedLanguage
})


export default connect(mapStateToProps, null)(React.memo(Translate));
